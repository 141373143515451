<template>
  <div>
    <Sect>
      <Wrap>
        <h2>"Swedbank" paskola</h2>
        <p>
          Siekdami pasiūlyti Jums patrauklias sąlygas renkantis aplinką tausojančius sprendimus, kruopščiai rinkomės savo partnerius, kurie atlieptų mūsų tikslus ir vertybes. Džiaugiamės galėdami Jums pristatyti savo partnerystę su ‘’Swedbank’’, kuris jau ne vienerius metus vertina tvarumą kaip strateginės svarbos sritį, o tvarumo siekius integruoja į savo verslo procesus bei paslaugas.
        </p>

        <h3>Žalioji „Swedbank“ iniciatyva – nutolusių saulės elektrinių klientams.</h3>
        <p>
          Besirūpinantiems gamta ir pasirinkusiems naudoti saulės energiją elektros gamybai – išskirtinis „Swedbank“ pasiūlymas! <strong>Taikysime itin mažas 8% metines fiksuotas vartojimo paskolos palūkanas.</strong>
        </p>

        <h3>Kaip gauti vartojimo paskolą išskirtinėmis sąlygomis:</h3>
        <IconColumns :data="financing" :hasProgression="isDesktop">
          <div slot="text-2">
            <strong>Užpildykite</strong>
            <a href="https://www.swedbank.lt/private/credit/loans/newSmall/small_loanLT/new" target="_blank">
              vartojimo paskolos paraišką
            </a>
            „Swedbank“ interneto banke ir <strong>įveskite nuolaidos kodą SAULĖ</strong>.
          </div>
        </IconColumns>
        <p>
          <strong>SVARBU!</strong> Mažiausiai 90 proc. šios paskolos sumos turi būti panaudota įsigyjant nutolusios saulės elektrinės dalį iš  UAB „Saulės bendruomenė LT“ ir šią sumą pervedant   į UAB ‚„Saulės bendruomenė LT“ sąskaitą ne vėliau nei per 90 dienų nuo paskolos gavimo (neįvykdžius šios sąlygos, bankas turi teisę panaikinti palūkanų nuolaidą ir taikyti 16 % procentų metinę fiksuotų palūkanų normą).
        </p>
        <p class="center">
          Pasiūlymas galioja pateikus vartojimo paskolos paraišką 2019 10 01 – 2020 06 30.
        </p>
      </Wrap>
    </Sect>

    <FooterFinancing>
      <h2>Preliminari paskolos mėnesio įmoka:</h2>
      <Wrap class="loan-table">
        <SimpleTable
          class="inv"
          :cols="isDesktop ? [6, 6] : [5, 7]"
          :colsClasses="['bold center white', 'bold center white']"
          :head="paymentsHead"
          :rows="payments" />
      </Wrap>
      <Wrap class="loan-details">
        <p>
          Įmoka apskaičiuota laikantis prielaidų, kad sutarties trukmė yra 60 mėn., taikomos 8% palūkanos, sutarties administravimo mokestis 30 Eur, įmokos mokamos anuiteto metodu.
        </p>
        <p>
          Daugiau informacijos apie vartojimo paskolas tel. 1884 arba <a href="https://www.swedbank.lt">
            www.swedbank.lt
          </a>.
        </p>
        <p>
          Atkreipiame dėmesį, kad saulės elektrinių finansavimas yra Jūsų sutikimas naudotis finansavimo paslaugomis, o tai reiškia, kad Jūs prisiimate finansinius įsipareigojimus. Netinkamas finansinių įsipareigojimų vykdymas gali daryti neigiamą įtaką Jūsų kredito istorijai, pabranginti skolinimąsi, taip pat gali būti pradėtas priverstinis išieškojimas.
        </p>
        <h3>
          Tipinis bendros vartojimo kredito kainos metinės normos apskaičiavimo pavyzdys
        </h3>
        <p>
          Jei imtumėte 2000 Eur vartojimo kreditą, sutarties trukmė (ir Kredito grąžinimo terminas) – 5 metai, taikant 8% metinę fiksuotų palūkanų normą, mokant Banko nustatytą minimalų kasdienių paslaugų mokestį (0,70 Eur per mėnesį), 30 Eur sutarties administravimo mokestį, mėnesio įmokas 20,62 Eur mokant anuiteto metodu, bendra vartojimo kredito kainos metinė norma būtų 9,93 %, o bendra vartojimo kredito gavėjo mokama suma būtų 2505,38 Eur.
        </p>
        <p>
          Bendros vartojimo kredito kainos metinė norma ir bendra vartojimo kredito gavėjo mokama suma apskaičiuotos laikantis prielaidų, kad Kredito sutartis galios terminą lygų Kredito sutarties trukmei, Bankas visą Kredito sumą Kredito gavėjams išmoka Kredito sutarties pasirašymo dieną, iš Kredito sutarties kylančius savo įsipareigojimus Kredito sutarties šalys vykdys pagal Kredito sutartyje nustatytas sąlygas ir terminus, metinė palūkanų norma, Įkainių dydis ir kitos išlaidos išliks tokios pat kaip ir Kredito sutarties sudarymo metu ir bus toliau taikomos iki Kredito sutarties pabaigos.
        </p>
      </Wrap>
    </FooterFinancing>
  </div>
</template>

<script>
import FooterFinancing from './components/FooterFinancing'
import IconColumns from './components/landing/IconColumns'
import Meta from './mixins/Meta'
import SimpleTable from './components/SimpleTable'
import { getters } from './utils'

export default {
  mixins: [
    Meta(
      'Saulės elektrinių finansavimas su Swedbank',
      'Saulės elektrinių finansavimas Lietuvos gyventojams. APVA inicijuojama parama saulės elektrinėms skirta žmonėms, besirūpinantiems gamta ir pasirinkusiems naudoti saulės energiją elektros gamybai. Sužinokite daugiau apie finansavimo galimybes ir vartojimo paskolos sąlygas.',
      require('@/assets/social/financing.jpg'),
    ),
  ],
  components: { FooterFinancing, IconColumns, SimpleTable },
  data() {
    return {
      financing: [
        {
          icon: 1,
          // eslint-disable-next-line xss/no-mixed-html
          text: '<strong>Rezervuokite</strong> nutolusios saulės elektrinės dalį iš „Saulės bendruomenė LT“.',
        },
        {
          icon: 2,
        },
        {
          icon: 3,
          text: 'Atsakymą dėl paskolos Jums pateiksime telefonu arba SMS žinute.',
        },
        {
          icon: 4,
          text: 'Sudarius kredito sutartį pinigus iš karto pervesime į Jūsų sąskaitą.',
        },
      ],
      paymentsHead: ['Paskolos suma', 'Preliminari mėn. įmoka'],
      payments: [
        ['1000 €', '20,62 €'],
        ['2000 €', '41,23 €'],
        ['3000 €', '61,84 €'],
      ],
    }
  },
  computed: getters('isDesktop'),
}
</script>

<style lang="scss">
</style>
