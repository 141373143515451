<template>
  <div id="FinancingInbank">
    <Sect>
      <Wrap>
        <h2>Pirkimas išsimokėtinai su „Inbank“</h2>
        <p>
          Ypač patogiai ir greitai įsigyti nutolusios saulės elektrinės dalį galite pirkdami išsimokėtinai. Jums tereikia priimti sprendimą, o mūsų partneris <strong>„Inbank“</strong> jums pateiks palankiausią finansavimo pasiūlymą.
        </p>
        <ul class="offer">
          <li><strong>3,9%</strong> palūkanos;</li>
          <li><strong>1,5%</strong> sutarties administravimo mokestis;</li>
          <li>Paskolos suma <strong>iki 15 000 eurų;</strong></li>
          <li>Terminas <strong>12-72 mėnesiai.</strong></li>
        </ul>

        <h2>Pirkimo išsimokėtinai privalumai</h2>
        <ul class="benefits">
          <li>Apsipirksite greitai – mokumo vertinimas bei sutarties pasirašymas vyksta internetu. Norėdami pasinaudoti šia galimybe kreipkitės į aptarnaujantį personalą;</li>
          <li>Perkant išsimokėtinai galite apmokėti visą sumą;</li>
          <li>Patys pasirinkite jums geriausią mokėjimo variantą: labiausiai tinkantį sutarties terminą ar įmokos dydį;</li>
          <li>Sutartį gali sudaryti du asmenys – sutuoktiniai, ir tokiu būdu pirkti išsimokėtinai palankesnėmis sąlygomis;</li>
          <li>Patogi „Inbank“ savitarna – greitai ir patogiai galėsite tvarkyti savo sutartis bei pervesti įmokas. Taip pat įmokas sumokėti galite „Maxima“ kasose, „Narvesen“ kioskuose, „PayPost“ skyriuose, „Perlo“ terminaluose bei bankiniu pavedimu;</li>
          <li>Galite atsiskaityti anksčiau laiko be papildomų išlaidų.</li>
        </ul>

        <Btn
          class="ctaOffer"
          :to="{ name: 'Contact', hash: '#FormContact' }">
          Gauti pasiūlymą
        </Btn>
      </Wrap>
    </Sect>

    <FooterFinancing class="inbank">
      <h2>Preliminari paskolos mėnesio įmoka:</h2>
      <Wrap class="loan-table">
        <SimpleTable
          class="inv"
          :cols="isDesktop ? [6, 6] : [5, 7]"
          :colsClasses="['bold center white', 'bold center white']"
          :head="paymentsHead"
          :rows="payments" />
      </Wrap>
      <Wrap class="loan-details">
        <p>
          Įmokos apskaičiuotos naudojantis prielaida, kad paskola imama 72 mėn. laikotarpiui.
        </p>
        <p>
          Pavyzdžiui, skolinantis 5000 €, kai sutartis sudaroma 72 mėn. terminui, metinė palūkanų norma – 3,9 %, sutarties mokestis – 74,67 €, BVKKMN – 4,5 %, bendra mokėtina suma – 5699,52 €, mėnesio įmoka – 79,16 €. Jums gali būti pasiūlytos ir kitokios sąlygos, atsižvelgiant į lizingo bendrovės atliktą Jūsų mokumo ir rizikos vertinimą ar pasirinkus kitokį sutarties terminą bei įmokų mokėjimo dieną.
        </p>
        <p>
          Naudodamiesi finansavimo paslaugomis, Jūs prisiimate finansinius įsipareigojimus. Prieš priimdami sprendimą dėl paskolos ir jos dydžio, atidžiai įvertinkite savo finansines galimybes.
        </p>
      </Wrap>
    </FooterFinancing>
  </div>
</template>

<script>
import FooterFinancing from './components/FooterFinancing'
import Meta from './mixins/Meta'
import SimpleTable from './components/SimpleTable'
import { getters } from './utils'

export default {
  mixins: [
    Meta(
      'Saulės elektrinių finansavimas su Inbanbk',
      'Saulės elektrinių finansavimas Lietuvos gyventojams. APVA inicijuojama parama saulės elektrinėms skirta žmonėms, besirūpinantiems gamta ir pasirinkusiems naudoti saulės energiją elektros gamybai. Sužinokite daugiau apie finansavimo galimybes ir vartojimo paskolos sąlygas.',
      require('@/assets/social/financing.jpg'),
    ),
  ],
  components: { FooterFinancing, SimpleTable },
  data() {
    return {
      paymentsHead: ['Paskolos suma', 'Preliminari mėn. įmoka'],
      payments: [
        ['2000 €', '31,66 €'],
        ['3000 €', '47,49 €'],
        ['5000 €', '79,16 €'],
        ['8000 €', '126,66 €'],
        ['10 000 €', '158,32 €'],
      ],
    }
  },
  computed: getters('isDesktop'),
}
</script>

<style lang="scss">
#FinancingInbank {
  .ctaOffer {
    margin: 2rem auto;
    max-width: 15rem;
  }

  ul.offer {
    list-style-position: inside;
    margin-bottom: 3rem;
    text-align: center;
    @include lg {
      margin-bottom: unset;
    }
  }

  ul.benefits {
    line-height: 2;
    margin: 0 2rem;
    text-align: left;
  }

  .FooterFinancing.inbank {
    margin-top: 2rem;
    @include md {
      margin-top: 5rem;
    }
  }
}
</style>
