<template>
  <div class="SimpleTable">
    <Row v-if="head">
      <Column
        v-for="(value, colIndex) in head"
        :key="colIndex"
        :xs="cols[colIndex]">
        <div class="cell center head">
          {{ value }}
        </div>
      </Column>
    </Row>
    <Row v-for="(row, index) in rows" :key="index">
      <Column
        v-for="(value, colIndex) in row"
        :key="colIndex"
        :xs="cols[colIndex]">
        <div class="cell" :class="colsClasses[colIndex]">
          {{ value }}
        </div>
      </Column>
    </Row>
  </div>
</template>

<script>
import { getters } from '../utils'

export default {
  props: {
    colsClasses: {
      type: Array,
      default: () => ['neutral', 'main bold center'],
    },
    cols: {
      type: Array,
      default: () => [6, 6],
    },
    head: Array,
    rows: Array,
  },
  computed: getters('isDesktop'),
}
</script>

<style lang="scss">
.SimpleTable {
  .Row {
    margin-bottom: 0.25rem;
  }

  .cell {
    border-radius: $radius;
    display: flex;
    height: 100%;
    justify-content: inherit;
    line-height: 1.2rem;
    padding: 0.5rem 0.25rem;
    @include lg {
      font-size: $h25;
      line-height: 3rem;
      min-height: 0;
      padding: 0.25rem 0.75rem 0;
    }

    &.head {
      font-weight: $regular;
      line-height: 2rem;
    }

    // color
    &.white {
      background: $white;
      color: $main;
    }

    &.neutral {
      background: $grey-lightest;
      color: $text-color-mid;
    }

    &.main {
      background: $main;
      color: $white;
    }

    // font
    &.bold {
      font-size: $h4;
      font-weight: $semibold;
    }
    // &.light {
    //   font-weight: $regular;
    // }

    // text
    &.center {
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
