<template>
  <div id="Calculator">
    <Calculator
      :project="mainProject"
      :value.sync="amount" />
  </div>
</template>

<script>
import Calculator from './components/Calculator'
import Meta from './mixins/Meta'
import { getters } from './utils'

export default {
  mixins: [
    Meta(
      'Saulės energijos kainos skaičiuoklė',
      'Virtuali saulės energijos kainos skaičiuoklė, leidžianti apskaičiuoti saulės elektrinės galią, metinę elektros energijos gamybą, numatyti išlaidas, investicijos atsipirkimo laiką ir kt. Saulės elektrinės skaičiuoklė paremta individualiu suvartojamos elektros energijos kiekiu per mėnesį.',
      require('@/assets/social/calculator.jpg'),
    ),
  ],
  components: { Calculator },
  data() {
    return {
      amount: 200,
    }
  },
  computed: getters('mainProject'),
}
</script>

<style lang="scss">
.Page-layout > #Calculator {
  margin-bottom: 6rem;
  margin-top: 2rem;
  @include lg {
    margin-bottom: 2rem;
  }
}
</style>
