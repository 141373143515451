<template>
  <div class="ReserveGuest">
    <Hero v-if="!isAuth" src="../assets/faq/hero.jpg" />
    <Wrap>
      <Breadcrumbs creationBreadcrumbKey="INFO" :hasSkippedReservation="hasSkippedReservation" />
      <Sect>
        <h2>Užsakovo duomenys</h2>
        <transition name="slide-fade">
          <div>
            <Notice class="warning">
              <template>
                Užpildykite duomenis
              </template>
            </Notice>
            <FormSignup
              id="signup"
              :doShowTitle="false"
              formType="reserve"
              :hasSkippedReservation.sync="hasSkippedReservation" />
          </div>
        </transition>
      </Sect>
    </Wrap>
  </div>
</template>

<script>
import Breadcrumbs from './components/Breadcrumbs'
import FormSignup from './components/forms/FormSignup'
import Hero from './components/landing/Hero'
import { getters } from './utils'

export default {
  components: {
    Breadcrumbs,
    FormSignup,
    Hero,
  },
  data() {
    return {
      hasSkippedReservation: false,
    }
  },
  computed: getters(),
}
</script>
<style lang="scss">
.ReserveGuest {
  .Breadcrumbs {
    margin-top: 3rem;
  }

  .Hero {
    min-height: 30vh;
  }
}
</style>
