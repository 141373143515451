<template>
  <div class="Page-layout">
    <!-- <PageHero /> -->
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer'

export default {
  components: { Footer },
}
</script>

<style lang="scss">
.Page-layout {
  h2 {
    font-size: $h3;
    @include lg {
      font-size: $h2;
      margin-top: 5rem;
    }
  }

  h3 {
    // margin: 3rem 0 1.5rem;
    text-align: center;
  }

  p {
    font-size: $h5;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    @include lg {
      font-size: $h4;
      margin-bottom: 2rem;
      // text-indent: 2rem;
    }
    // text-indent: 1rem;
    &.center {
      text-align: center;
    }
  }

  > .Sect {
    h2 {
      margin-bottom: 1rem;
      @include lg {
        margin-bottom: 4rem;
      }
    }

    > .Wrap {
      padding: 0 2rem;
      @include lg {
        padding: 0 1rem;
      }
    }
  }
}
</style>
