<template>
  <div class="Faq">
    <Hero src="../assets/faq/hero.jpg">
      <h1>Dažniausiai užduodami klausimai</h1>
    </Hero>
    <Sect>
      <Wrap class="lg">
        <Qa
          v-for="({ q, a }, index) in faqs"
          :key="index"
          :answer="a"
          :question="q" />
      </Wrap>
    </Sect>
  </div>
</template>

<script>
import Hero from './components/landing/Hero'
import Meta from './mixins/Meta'
import Qa from './components/Qa'
import faqs from './data/faq'

export default {
  mixins: [
    Meta(
      'Dažniausiai užduodami klausimai',
      'Dažniausiai užduodami klausimai ir išsami informacija apie saulės elektros energijos gamybą, įsigijimą bei vartojimą. Kaip prisijungti prie SAULĖS BENDRUOMENĖS projekto? Kaip įsigyti dalį nutolusios saulės elektrinės? Kas nutiks pakeitus gyvenamąją vietą? Per kiek laiko atsipirks mano investicija?',
      require('@/assets/social/faq.jpg'),
    ),
  ],
  components: { Hero, Qa },
  data() {
    return { faqs }
  },
}
</script>
<style lang="scss">
.Faq {
  background: $white-ter;

  h1 {
    @include dotBefore(8rem);
  }

  .Btn {
    border: 1px solid $black;
    width: unset;

    .circle {
      margin-left: -1rem;
    }
  }
}
</style>
