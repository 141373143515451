<template>
  <div class="Breadcrumbs">
    <ProgressBar :class="{ hasSkippedReservation: isReservationPaymentSkipped }" :steps="breadcrumbs" />
  </div>
</template>
<script>
import { actions, getters } from 'views/utils'
import ProgressBar from 'components/admin/ProgressBar'

const breadcrumbStatuses = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  UNAVAILABLE: 'UNAVAILABLE',
}

const breadcrumbsOrder = [
  'CAPACITY',
  'INFO',
  'RESERVATION',
  'ADVANCE',
  'MAIN',
  'MAINTENANCE',
]

const getTheme = status => ({
  // PENDING: 'warning',
  // COMPLETE: 'success',
  PENDING: 'info pending',
  COMPLETE: 'info complete',
  UNAVAILABLE: null,
})[status] || null

export default {
  components: { ProgressBar },
  props: {
    creationBreadcrumbKey: String,
    hasSkippedReservation: Boolean,
    reservationId: Number,
  },
  data() {
    return {
      isReservationPaymentSkipped: false,

      creationBreadcrumbRoutesPreview: {
        CAPACITY: {
          name: 'SelfReservationAddPreview',
        },
        INFO: {
          name: 'ReserveUserPreview',
        },
      },
    }
  },
  computed: {
    creationBreadcrumbRoutes() {
      return {
        CAPACITY: {
          name: this.authUser ? 'SelfReservationAdd' : 'SelfReservationAddGuest',
        },
        // INFO: {
        //   name: 'ReserveUser',
        // },
      }
    },
    reservation() {
      if (!this.reservationId) return

      return this.query('reservation', this.reservationId)
    },
    reservationPayments() {
      if (!this.reservationId) return []

      return this.query('payment', {
        reservationId: this.reservationId,
      })
    },
    breadcrumbs() {
      return [
        {
          tag: 'CAPACITY',
          getStatus: this.getCreationBreadcrumbStatus,
          name: 'Galia',
          getRoute: this.getCreationBreadcrumbRoute,
        },
        {
          tag: 'INFO',
          getStatus: this.getCreationBreadcrumbStatus,
          name: 'Duomenys',
          getRoute: this.getCreationBreadcrumbRoute,
        },
        ...this.isReservationPaymentSkipped
          ? []
          : [{
            tag: 'RESERVATION',
            getStatus: this.getBreadcrumbStatus,
            name: 'Rezervacinis mok.',
            getRoute: this.getBreadcrumbRoute,
          }],
        {
          tag: 'ADVANCE',
          getStatus: this.getBreadcrumbStatus,
          name: 'Avansinis mok.',
          getRoute: this.getBreadcrumbRoute,
        },
        {
          tag: 'MAIN',
          getStatus: this.getBreadcrumbStatus,
          name: 'Pirkimo-pardavimo akt.',
          getRoute: this.getBreadcrumbRoute,
        },
        {
          tag: 'MAINTENANCE',
          getStatus: this.getBreadcrumbStatus,
          name: 'Priežiūros mokestis',
          getRoute: this.getBreadcrumbRoute,
        },
      ].map(breadcrumb => ({
        ...breadcrumb,
        status: breadcrumb.getStatus(breadcrumb.tag),
      }))
        .map(breadcrumb => ({
          ...breadcrumb,
          theme: getTheme(breadcrumb.status),
          route: breadcrumb.getRoute(breadcrumb.status, breadcrumb.tag),
        }))
    },
    isReservationCreated() {
      return !!this.reservationId
    },
    ...getters(),
  },
  methods: {
    getBreadcrumbRoute(status) {
      if (status === breadcrumbStatuses.UNAVAILABLE) return null

      return { name: 'Reservation', params: { id: this.reservation.id } }
    },
    getCreationBreadcrumbRoute(status, breadcrumbKey) {
      if (this.isReservationCreated) {
        return {
          ...this.creationBreadcrumbRoutesPreview[breadcrumbKey],
          params: {
            id: this.reservation.projectId,
            reservationId: this.reservation.id,
          },
        }
      }

      if (status !== breadcrumbStatuses.COMPLETE) return null

      return this.creationBreadcrumbRoutes[breadcrumbKey]
    },
    getCreationBreadcrumbStatus(breadcrumbKey) {
      if (this.isReservationCreated) return breadcrumbStatuses.COMPLETE

      if (this.creationBreadcrumbKey === breadcrumbKey) return breadcrumbStatuses.PENDING

      const currentBreadcrumbStepIndex = breadcrumbsOrder.indexOf(this.creationBreadcrumbKey)
      if (breadcrumbsOrder.indexOf(breadcrumbKey) < currentBreadcrumbStepIndex) return breadcrumbStatuses.COMPLETE

      return breadcrumbStatuses.UNAVAILABLE
    },
    getBreadcrumbStatus(breadcrumbKey) {
      if (this.isPaymentPaid(breadcrumbKey)) return breadcrumbStatuses.COMPLETE

      if (this.isPaymentCreated(breadcrumbKey)) return breadcrumbStatuses.PENDING

      return breadcrumbStatuses.UNAVAILABLE
    },
    isPaymentCreated(breadcrumbKey) {
      return this.reservationPayments.some(payment => payment.type === breadcrumbKey)
    },
    isPaymentPaid(breadcrumbKey) {
      const payments = this.reservationPayments.filter(payment => payment.type === breadcrumbKey)

      if (!payments.length) return false

      const paymentsPaid = payments.filter(payment => !!payment.paidAt)

      return payments.length === paymentsPaid.length
    },
    assertReservationPaymentChoice() {
      if (!this.isReservationCreated) {
        this.isReservationPaymentSkipped = !!this.hasSkippedReservation
        return
      }

      this.isReservationPaymentSkipped = this.reservation.hasSkippedReservation
    },
    ...actions('FETCH_PAYMENT'),
  },
  watch: {
    hasSkippedReservation: {
      immediate: true,
      handler(hasSkippedReservation) {
        if (hasSkippedReservation === this.isReservationPaymentSkipped) return
        this.assertReservationPaymentChoice()
      },
    },
  },
  async mounted() {
    this.assertReservationPaymentChoice()
  },
}
</script>

<style lang="scss">
.Breadcrumbs {
  margin-bottom: 6rem;
  @include lg {
    margin-bottom: unset;
  }

  .ProgressBar {
    .ProgressBarStep {
      float: left;
      font-size: 0.6rem;
      padding: 0;
      text-align: center;
      width: calc(100% / 6);
      @include md {
        font-size: unset;
      }

      &::before {
        display: block;
      }

      &::after {
        content: ' ';
      }

    }

    &.hasSkippedReservation {
      .ProgressBarStep {
        width: calc(100% / 5);
      }
    }
  }
}
</style>
