<template>
  <div class="Qa">
    <div
      class="question"
      :class="{ active: isOpen }"
      @click="isOpen = !isOpen"
      @keydown.enter="isOpen = !isOpen">
      <div v-html="question" />
      <div class="icon">
        <Btn class="arrow no-text" :class="{ up: isOpen, down: !isOpen }" />
      </div>
    </div>
    <transition name="slide-fade">
      <div v-show="isOpen" class="answer">
        <p v-for="(para, index) in answer" :key="index" v-html="para" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    answer: Array,
    question: String,
  },
  data() {
    return {
      isOpen: false,
    }
  },
}
</script>

<style lang="scss">
.Qa {
  background: $white;
  border-bottom: 1px solid $border-color;
  border-radius: $radius 0;
  box-shadow: $shadow-sm;
  margin-bottom: 1rem;
  padding: 2rem;

  > .question {
    cursor: pointer;
    display: flex;
    font-size: $h4;
    justify-content: space-between;
    padding: 1rem 0;
    position: relative;
    @include lg {
      font-size: $h25;
      line-height: 2.5rem;
    }

    > .icon {
      align-items: center;
      color: $yellow;
      display: flex;
      float: right;
      // font-size: $h3;
      padding: 0 0.5rem;
      @include lg {
        font-size: $h2;
      }
    }

    &.active {
      border-bottom: 2px solid $text-color;
    }
  }

  > .answer {
    padding-top: 1rem;

    p {
      font-size: $h5;
      margin-bottom: 1rem;
      @include lg {
        font-size: $h4;
        line-height: 2rem;
      }
    }
  }
}
</style>
