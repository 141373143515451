<template>
  <div id="FinancingBigBank">
    <Sect>
      <Wrap>
        <h2>Bigbank pirkimas išsimokėtinai</h2>
        <p>
          <strong>Bigbank</strong> paruošė Jums specialų pasiūlymą norint įsigyti dalį nutolusios saulės elektrinės:
        </p>
        <ul class="offer">
          <li><strong>4.6%</strong> metinės palūkanos visiems.</li>
          <li>Mokėjimo terminas – <strong>nuo 6 iki 120 mėn.</strong></li>
          <li>Nereikia pradinio įnašo - finansuojame <strong>100%</strong> pirkinio vertės.</li>
        </ul>

        <h2>Kaip pirkti išsimokėtinai</h2>
        <IconColumns :data="financing" :hasProgression="isDesktop">
          <div slot="text-2">
            Kreipkitės į <strong>Bigbank</strong> vadybininkus telefonu <Phone number="+370 700 77701" /> arba rašykite <Email address="lizingas@bigbank.lt" />.
          </div>
        </IconColumns>
      </Wrap>
    </Sect>

    <FooterFinancing>
      <h2>Preliminari paskolos mėnesio įmoka:</h2>
      <Wrap class="loan-table">
        <SimpleTable
          class="inv"
          :cols="isDesktop ? [6, 6] : [5, 7]"
          :colsClasses="['bold center white', 'bold center white']"
          :head="paymentsHead"
          :rows="payments" />
      </Wrap>
      <Wrap class="loan-details">
        <p>
          Įmokos apskaičiuotos naudojantis prielaida, kad paskola imama 120 mėn. laikotarpiu.
        </p>
        <p>
          Pavyzdžiui, skolinantis 5 000 Eur, kai sudaroma 120 mėn. sutartis, fiksuota metinė palūkanų norma - 4,6%, sutarties sudarymo mokestis – 1,9% (grąžinamas lygiomis dalimis per visą sutarties galiojimo laikotarpį), mėnesio įmoka – 52,85 Eur, bendra sumokama suma – 6342,27 Eur, bendra kredito kainos metinė norma – 5,11%.
        </p>
        <p>
          Naudodamiesi finansavimo paslaugomis, Jūs prisiimate finansinius įsipareigojimus. Prieš priimdami sprendimą dėl paskolos ir jos dydžio, atidžiai įvertinkite savo finansines galimybes.
        </p>
      </Wrap>
    </FooterFinancing>
  </div>
</template>

<script>
import FooterFinancing from './components/FooterFinancing'
import IconColumns from './components/landing/IconColumns'
import Meta from './mixins/Meta'
import SimpleTable from './components/SimpleTable'
import { getters } from './utils'

export default {
  mixins: [
    Meta(
      'Saulės elektrinių finansavimas su Bigbanbk',
      'Saulės elektrinių finansavimas Lietuvos gyventojams. APVA inicijuojama parama saulės elektrinėms skirta žmonėms, besirūpinantiems gamta ir pasirinkusiems naudoti saulės energiją elektros gamybai. Sužinokite daugiau apie finansavimo galimybes ir vartojimo paskolos sąlygas.',
      require('@/assets/social/financing.jpg'),
    ),
  ],
  components: { FooterFinancing, IconColumns, SimpleTable },
  data() {
    return {
      financing: [
        {
          icon: 1,
          text: 'Rezervuokite nutolusios saulės elektrinės dalį.',
        },
        {
          icon: 2,
        },
        {
          icon: 3,
          text: 'Sudarykite pirkimo išsimokėtinai sutartį.',
        },
        {
          icon: 4,
          text: 'Jūsų pirkinys bus finansuotas tiesiai į pardavėjo sąskaitą.',
        },
      ],
      paymentsHead: ['Paskolos suma', 'Preliminari mėn. įmoka'],
      payments: [
        ['1000 €', '10,57 €'],
        ['3000 €', '31,71 €'],
        ['5000 €', '52,85 €'],
        ['8000 €', '84,56 €'],
        ['10 000 €', '105,70 €'],
      ],
    }
  },
  computed: getters('isDesktop'),
}
</script>

<style lang="scss">
#FinancingBigBank {
  ul.offer {
    list-style-position: inside;
    text-align: center;
  }
}
</style>
