<template>
  <div>
    <Sect>
      <Wrap>
        <h2>APVA Parama saulės elektrinių įsirengimui</h2>
        <p>
          Saulės elektrinių finansavimas ir parama Lietuvos gyventojams – pagalba žmonėms, norintiems prisidėti prie alternatyvios energijos naudojimo ir aplinkosaugos. Naudokite atsinaujinančią elektros energiją ir pasinaudokite patogiomis finansavimo galimybėmis.
        </p>
        <p>
          Lietuvos gyventojams gali būti teikiama parama saulės elektrinių įsigijimui. Paramą inicijuoja APVA – Lietuvos Respublikos aplinkos ministerijos Aplinkos projektų valdymo agentūra, administruojanti Europos Sąjungos fondų (ISPA, Europos regioninės plėtros, Sanglaudos fondų, LIFE+ programos), LAAIF, Klimato kaitos, Atliekų tvarkymo, Aplinkos apsaugos rėmimo programų ir valstybės lėšomis finansuojamus aplinkos sektoriaus projektus.
        </p>
        <p>
          Šiuo metu galite teikti paraiškas dėl paramos saulės elektrinių įsigijimui iš elektrinių parkų iki <b>2022 m. vasario 11 d. 15:45 val.</b> Svarbiausius klausimus apie paramą saulės elektrinėms įsirengti bei aktualią informaciją apie registracijos formų pildymą galite rasti
          <a href="https://www.apva.lt/aie-namu-ukiams-isigijimas-is-elektriniu-parku/" rel="noopener" target="_blank\">
            ČIA
          </a>.
        </p>
        <div class="banks">
          <h2>Finansavimo galimybės</h2>
          <Row>
            <Column v-for="bank in banks" :key="bank" :md="4">
              <Route :to="`Financing${bank}`">
                <Pic
                  :alt="bank"
                  :src="`../assets/banks/${bank.toLowerCase()}-logo.png`" />
              </Route>
            </Column>
          </Row>
        </div>
      </Wrap>
    </Sect>

    <Footer class="financing" />
  </div>
</template>

<script>
import Footer from './components/Footer'
import Meta from './mixins/Meta'

export default {
  mixins: [
    Meta(
      'Saulės elektrinių finansavimas',
      'Saulės elektrinių finansavimas Lietuvos gyventojams. APVA inicijuojama parama saulės elektrinėms skirta žmonėms, besirūpinantiems gamta ir pasirinkusiems naudoti saulės energiją elektros gamybai. Sužinokite daugiau apie finansavimo galimybes ir vartojimo paskolos sąlygas.',
      require('@/assets/social/financing.jpg'),
    ),
  ],
  components: { Footer },
  data() {
    return {
      banks: [
        'Bigbank',
        'Swedbank',
        'Inbank',
      ],
    }
  },
}
</script>

<style lang="scss">
.Page-layout.Financing {
  h3 {
    font-size: $h5;
    margin: 2rem 0 1rem;
    text-transform: none;
    @include lg {
      font-size: $h4;
      margin: 3rem 0 2rem;
    }
  }

  p {
    text-align: center;
  }

  .IconColumns {
    font-size: $h4;
    margin-top: 3rem;

    strong {
      color: $main;
    }
  }

  .banks {
    margin: 2rem 0 4rem;
    text-align: center;

    .Row .Column {
      > a {
        display: inline-block;
        margin-right: 2rem;
        padding: 1rem;

        img {
          max-height: 4rem;
        }
      }
    }
  }

}

.Footer.financing {
  @include md {
    margin-top: 3rem;
  }
  @include lg {
    margin-top: 5rem;
  }
}
</style>
