<template>
  <Footer class="FooterFinancing">
    <div slot="blobs">
      <Blob class="top-left" name="footer-blob-top-left" />
    </div>
    <slot />
  </Footer>
</template>

<script>
import Footer from './Footer'

export default {
  components: { Footer },
}
</script>

<style lang="scss">
#Footer.FooterFinancing {
  // background-image: linear-gradient(#446be0, #5f35d2);
  h2 {
    font-size: $h3;
    margin-bottom: 1rem;
    @include lg {
      font-size: $h25;
      margin-bottom: 2.5rem;
    }
  }

  .SimpleTable {
    margin-bottom: 2rem;
  }

  .Wrap.loan-table {
    max-width: 1000px;
  }

  .Wrap.loan-details {
    max-width: 1150px;

    h3 {
      margin: 2rem 0 1rem;
    }

    p {
      line-height: $lh;
      text-align: center;
    }
  }
}
</style>
