<template>
  <div class="Page-layout Financing">
    <PageHero />
    <router-view />
  </div>
</template>

<script>
import Page from './Page'

export default {
  mixins: [Page],
}
</script>

<style lang="scss">
.Page-layout.Financing {
  h3 {
    font-size: $h5;
    margin: 2rem 0 1rem;
    text-transform: none;
    @include lg {
      font-size: $h3;
      margin: 3rem 0 2rem;
    }
  }

  ul li {
    font-size: $h4;
    margin-bottom: 0.5rem;
  }

  p {
    text-align: center;
  }

  .IconColumns {
    font-size: $h4;
    margin-top: 3rem;

    strong {
      color: $main;
    }
  }
}
</style>
