<template>
  <div id="SelfReservationAdd">
    <Hero v-if="!isAuth" class="unAuth" src="../assets/faq/hero.jpg" />
    <Wrap>
      <Breadcrumbs
        :class="{ padded: isGuest }"
        creationBreadcrumbKey="CAPACITY"
        :reservationId="reservationId" />
    </Wrap>
    <Loader
      v-if="!project" />

    <Wrap v-if="project" class="lg project">
      <CardProject :displayNameOverlay="true" :project="project" />
    </Wrap>

    <Wrap v-if="project && project.status === 'ACTIVE'" class="form-wrap">
      <FormValidation class="form" @submit="submit">
        <div class="SelfReservationAdd-custom-amount">
          <Field
            data-cy="capacity"
            :disabled="isInReviewMode"
            label="Norima saulės elektrinės galia, kW"
            :max="capacityMax"
            :min="1"
            :step="0.1"
            type="number"
            :value.sync="pvCapacity" />
        </div>
        <Btn
          v-if="!isInReviewMode"
          class="arrow"
          data-cy="confirmCapacity"
          :disabled="!pvCapacity || pvCapacity < 1 || pvCapacity > capacityMax"
          type="submit">
          Noriu rezervuoti {{ pvCapacity | commaDecimal(1) }} kW
        </Btn>
        <!--
        <Btn
          v-if="!hasCustomCapacity"
          class="inv no-border"
          key="reserve"
          @click="hasCustomCapacity = true">
          Noriu pats nustatyti elektrinės galią
        </Btn>
        -->
      </FormValidation>
    </Wrap>
    <Calculator
      v-if="project"
      :disabled="isInReviewMode"
      :project="project"
      :value.sync="amount"
      @pvCapacity="setPvCapacity" />
  </div>
</template>

<script>
import { actions, getters } from './utils'
import Breadcrumbs from './components/Breadcrumbs'
import Calculator from './components/Calculator'
import CardProject from './components/CardProject'
import Hero from './components/landing/Hero'

export default {
  components: { Breadcrumbs, Calculator, CardProject, Hero },
  data() {
    return {
      amount: 200,
      reservationId: Number(this.$route.params.reservationId),
      // hasCustomCapacity: false,
      pvCapacity: 0,
      capacityMax: 5000,
    }
  },
  computed: {
    isInReviewMode() {
      return !!this.$route.params.reservationId
    },
    project() {
      return this
        .queryOne('project', this.$route.params.id)
    },
    isGuest() {
      // TODO: hotfix, make layout for guest/better idea
      return this.$route.path.includes('svecias')
    },
    ...getters('localStore'),
  },
  methods: {
    setPvCapacity(pvCapacity) {
      this.pvCapacity = parseFloat(pvCapacity.toFixed(1), 10)
    },
    submit() {
      this.SET_CAPACITY_CACHE(this.pvCapacity)

      this.$router.push({
        name: this.authUser ? 'ReserveUser' : 'ReserveGuest',
        params: {
          amount: Math.round(this.pvCapacity * 100, 10),
          id: this.project.id,
        },
      })
    },
    ...actions('SET_CAPACITY_CACHE'),
  },
  mounted() {
    if (!this.reservationId) {
      if (this.localStore('capacityCache')) this.setPvCapacity(this.localStore('capacityCache'))

      return
    }

    const reservation = this.queryOne('reservation', this.$route.params.reservationId)
    if (!reservation) return

    this.pvCapacity = reservation.capacity
  },
}
</script>

<style lang="scss">
#SelfReservationAdd {
  background: $white-ter;
  padding-bottom: 5rem;

  .Hero {
    min-height: 30vh;
  }

  .Hero.unAuth {
    min-height: 15vh;
  }

  .Breadcrumbs.padded {
    padding-top: 4rem;
  }

  .CardProject {
    width: 100%;

    .details {
      .top {
        display: none;
      }

      .bottom {
        margin-bottom: 1rem;
      }
    }

    .detail {
      border-left: unset;
      border-top: 0;
      @include xl {
        border-left: solid 2px $black;

        &:first-of-type {
          border-left: none;
          padding-left: 0;
        }
      }

      &:first-of-type, &:nth-of-type(2) {
        border-bottom: 2px solid $black;
        @include xl {
          border-bottom: unset;
        }
      }
    }

    .preview {
      height: 16rem;
    }
  }

  .form-wrap {
    margin-bottom: 2rem;
    margin-top: 4rem;
    max-width: 47rem;

    .SelfReservationAdd-custom-amount {
      display: block;

      .Field-cont {
        text-align: center;

        input {
          text-align: center;
        }
      }
    }
  }
}
</style>
